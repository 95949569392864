"use client";
import React from 'react'
import { AiFillGithub } from "react-icons/ai";
import { IoMdInformationCircle } from "react-icons/io";

function Projects() {
  return (
    <div>
      <div className='mt-5'>
        <h1 className='text-3xl font-bold text-black dark:text-white transition-colors duration-1000'>Personal Projects</h1>
        <div className='py-5 flex gap-5 flex-wrap'>

        <div className="card card-compact w-96 bg-zinc-400 shadow-md dark:bg-zinc-800 indicator transition-colors duration-1000">
        <span className="indicator-item indicator-top indicator-center badge bg-pink-600 text-white border-none pointer-events-none">new!</span>
            <div className="card-body text-zinc-900 dark:text-white transition-colors duration-1000">
              <h2 className="card-title">Successerator</h2>
              <p>"Success"/To-Do List Android Application</p>
              <div className="card-actions justify-between">
                <div className='gap-3 flex'>
                  <div className="badge badge-outline">Android Studio</div> 
                  <div className="badge badge-outline">Java</div>
                </div>
                <div className='text-3xl text-lime-500 gap-2 flex'>
                  <div className="tooltip tooltip-bottom" data-tip="More Info">
                    <a target="_blank" rel="noreferrer" href="#" ><IoMdInformationCircle /></a>
                  </div>
                  <div className="tooltip tooltip-bottom" data-tip="View Github">
                    <a target="_blank" rel="noreferrer" href="https://github.com/CSE-110-Winter-2024/project-team-13/" ><AiFillGithub /></a>
                  </div>
                </div>            
              </div>
            </div>
          </div>

          <div className="card card-compact w-96 bg-zinc-400 shadow-md dark:bg-zinc-800 transition-colors duration-1000">
            <div className="card-body text-zinc-900 dark:text-white transition-colors duration-1000">
              <h2 className="card-title">dungeon ranker</h2>
              <p>Hypixel Skyblock stats tracker.</p>
              <div className="card-actions justify-between">
                <div className='gap-3 flex'>
                  <div className="badge badge-outline">Python</div> 
                  <div className="badge badge-outline">API</div>
                  <div className="badge badge-outline">Flask</div>
                </div>
                <div className='text-3xl text-yellow-400 gap-2 flex'>
                  <div className="tooltip tooltip-bottom" data-tip="More Info">
                    <a target="_blank" rel="noreferrer" href="#" ><IoMdInformationCircle /></a>
                  </div>
                  <div className="tooltip tooltip-bottom" data-tip="View Github">
                    <a target="_blank" rel="noreferrer" href="https://github.com/kevin-dough/dungeon-ranker" ><AiFillGithub /></a>
                  </div>
                </div>            
              </div>
            </div>
          </div>

          <div className="card card-compact w-96 bg-zinc-400 shadow-md dark:bg-zinc-800 transition-colors duration-1000">
            <div className="card-body text-zinc-900 dark:text-white transition-colors duration-1000">
              <h2 className="card-title">Hydrogen Fuel Web Scraper</h2>
              <p>Web scraper for hydrogen fueling stations in CA.</p>
              <div className="card-actions justify-between">
                <div className='gap-3 flex'>
                  <div className="badge badge-outline">Python</div> 
                  <div className="badge badge-outline">Playwright</div> 
                </div>
                <div className='text-3xl text-blue-500 gap-2 flex'>
                  <div className="tooltip tooltip-bottom" data-tip="More Info">
                    <a target="_blank" rel="noreferrer" href="#" ><IoMdInformationCircle /></a>
                  </div>
                  <div className="tooltip tooltip-bottom" data-tip="View GitHub">
                    <a target="_blank" rel="noreferrer" href="https://github.com/kevin-dough/hydrogen-scraper/" ><AiFillGithub /></a>
                  </div>
                </div>            
              </div>
            </div>
          </div>

          <div className="card card-compact w-96 bg-zinc-400 shadow-md dark:bg-zinc-800 transition-colors duration-1000">
            <div className="card-body text-zinc-900 dark:text-white transition-colors duration-1000">
              <h2 className="card-title">Hypixel Player Status</h2>
              <p>Track online status of Hypixel players.</p>
              <div className="card-actions justify-between">
                <div className='gap-3 flex'>
                  <div className="badge badge-outline">Python</div> 
                  <div className="badge badge-outline">API</div>
                </div>
                <div className='text-3xl text-purple-500 gap-2 flex'>
                  <div className="tooltip tooltip-bottom" data-tip="More Info">
                    <a target="_blank" rel="noreferrer" href="https://github.com/kevin-dough/dungeon-ranker" ><IoMdInformationCircle /></a>
                  </div>
                  <div className="tooltip tooltip-bottom" data-tip="View Github">
                    <a target="_blank" rel="noreferrer" href="https://github.com/kevin-dough/playerStatus" ><AiFillGithub /></a>
                  </div>
                </div>            
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Projects