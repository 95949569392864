"use client";
import { useState } from 'react';
import { FaSearch } from "react-icons/fa"

import React from 'react'

function Hydrogen() {
  const [search, setSearch] = useState('');
  function doSearch(value) {
    
  }
  return (
    <div>
      <div className="mx-auto text-center">
          <h1 className='text-2xl text-zinc-800 dark:text-zinc-200'>California Hydrogen Station WebScraper</h1>
          <div className='mt-3'>
            <form>
              <div className='mx-auto flex justify-center gap-2 max-w-sm'>
                <input className="outline-none bg-zinc-400 text-zinc-800 p-2" type="text" value={search} onChange={(e) => setSearch(e.target.value)} required/>
                <button className="bg-pink-600 px-3" type="submit" onSubmit={doSearch(search)}><FaSearch className='text-white'/></button>
              </div>
              <div className='mt-12 mx-auto'>

              </div>
            </form>
          </div>
          <p>{search}</p>
      </div>
    </div>
  )
}

export default Hydrogen