import { AiFillLinkedin, AiFillGithub } from "react-icons/ai";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { MdMail } from "react-icons/md";
import React from 'react'
import {useState, useEffect } from 'react';

function About() {
  const [scrollTop, setScrollTop] = useState(0);
  const scrollDown = () => {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    } else {
      document.getElementById('skills').scrollIntoView();
    }
  }

  useEffect(() => {
    const handleScroll = (event) => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <section className="min-h-screen">
        <div className="text-center mx-auto mt-10 text-md py-5 gap-y-2 leading-8 text-zinc-800 md:text-lg max-w-xl dark:text-gray-200 transition-colors duration-1000">
          <p className="text-3xl pb-10">Hello. I'm <span className="text-pink-500"> Kevin</span>.</p>
          <p className="pb-10">I'm a student developer from San Diego, CA.</p>
          <p className="pb-10">I'm going to begin my third year of studying Computer Science at UC San Diego.</p>
          <p                  >
            I've recently been exploring 2D games with <span className="underline italic">Unity</span> and <span className="underline italic">C#</span>. Many new projects are coming soon!</p>
          <p></p>
        </div>
        <hr id="hrLine" class="w-48 h-1 mx-auto my-4 bg-gray-700 border-0 rounded md:my-10"/>
        <div className="justify-center text-2xl flex gap-16 pt-5 text-pink-500">
          <div className="tooltip tooltip-bottom" data-tip="GitHub">
            <a target="_blank" rel="noreferrer" href="https://github.com/kevin-dough" ><AiFillGithub /></a>
          </div>
          <div className="tooltip tooltip-bottom" data-tip="LinkedIn">
            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/kevintdo50/" ><AiFillLinkedin /></a>
          </div>
          <div className="tooltip tooltip-bottom" data-tip="Email">
            <a target="_blank" rel="noreferrer" href="mailto:kevin12345do@gmail.com" ><MdMail /></a>
          </div>
        </div>
      </section>
          <IoIosArrowDropdownCircle id="scrollButton" onClick={scrollDown} className={`${scrollTop > 20 ? "rotate-180" : ""} bottom-12 right-12 fixed text-white hover:text-pink-500 hover:scale-150 delay-200 text-3xl lg:text-4xl transition ease-in-out duration-200 hidden sm:inline`}/>

      <section className="min-h-screen">
        <div className="pt-16 text-black dark:text-white transition-colors duration-1000">
          <h1 id="skills" className="text-3xl font-bold mb-2">Technical Skills</h1>
          {/* <div className="flex gap-3">(
            <span className="indicator-item badge bg-pink-500 text-black font-semibold">recently used</span> 
            <span className="indicator-item badge bg-sky-400 text-black font-semibold">learning</span> 
            )
          </div> */}
          <div>
            <h2 className="text-2xl">Languages</h2>
            <div className="p-2 gap-4 flex flex-wrap">
              {[
                ['Java',       ''],
                ['Python',     ''],
                ['C/C++',      ''],
                ['C#',         'hidden'],
                ['ARM/MIPS',   'hidden'],
                ['Bash',       'hidden'],
                ['HTML/CSS',   'hidden'],
                ['Javascript', 'hidden',],
              ].map(([item, highlight]) => (
                <div className="p-2 px-3 rounded-lg bg-zinc-400 shadow-md dark:bg-zinc-800 indicator transition-colors duration-1000">
                  <span className={`${highlight} indicator-item badge bg-pink-500`}></span> 
                  <h2 className="text-zinc-900 dark:text-white transition-colors duration-1000">{item}</h2>
                </div>
              ))}
            </div>
          </div>
          <div>
            <h2 className="text-2xl">Tools & Frameworks</h2>
            <div className="p-2 gap-4 flex flex-wrap">
              {[
                ['JUnit',              'hidden'],
                ['GDB',                'hidden'],
                ['Quart/Flask',        'hidden'],
                ['React',              'hidden'],
                ['TailwindCSS',        'hidden'],
                ['Firebase',           'hidden'],
                ['Figma',              'hidden'],
                ['VSCode',             'hidden'],
                ['Android Studio',     'hidden'],
                ['Vercel',             'hidden'],
                ['Windows',            'hidden'],
                ['Ubuntu',             'hidden'],
                ['macOS',              'hidden'],
                ['Git',                'hidden'],
                ['Swift',              'hidden'],
                ['Unity',              'hidden'],
              ].map(([item, highlight]) => (
                <div className="p-2 px-3 rounded-lg bg-zinc-400 shadow-md dark:bg-zinc-800 indicator transition-colors duration-1000">
                  {/* <span className={`${learning} indicator-item badge bg-sky-400`}></span>  */}
                  <span className={`${highlight} indicator-item badge bg-pink-500`}></span> 
                  <h2 className="text-zinc-900 dark:text-white transition-colors duration-1000">{item}</h2>
                </div>
              ))}
            </div>
          </div>
          <div>
            <h2 className="text-2xl">Techniques</h2>
            <div className="p-2 gap-4 flex flex-wrap">
              {[
                ['Agile software process', ''],
                ['Behavior Driven Development (BDD)', 'hidden'],
                ['Unit Testing', 'hidden'],
                ['Object Mocking', 'hidden'],
                ['Continuous Integration', 'hidden'],
                ['Single Responsibility Principle (SRP)', 'hidden'],
                ['Dependency Inversion', 'hidden'],
                ['Open-Closed Principle', 'hidden'],
                ['Design Patterns (Strategy, Observer, MVP, Abstract Factory, Builder', ''],
              ].map(([item, highlight]) => (
                <div className="p-2 px-3 rounded-lg bg-zinc-400 shadow-md dark:bg-zinc-800 indicator transition-colors duration-1000">
                  <span className={`${highlight} indicator-item badge bg-pink-500`}></span> 
                  <h2 className="text-zinc-900 dark:text-white transition-colors duration-1000">{item}</h2>
                </div>
              ))}
            </div>
          </div>
          
          
          

        </div>
      </section>
    </div>
  )
}

export default About